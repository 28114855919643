import { useEffect } from 'react';
import { Route } from 'react-router-dom';
import { IonApp, IonRouterOutlet } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import CalendarPage from './pages/CalendarPage';
import DesktopSplashPage from './pages/DesktopSplashPage';
import EventInfoPage from './pages/EventInfoPage';
import { useAppContext } from './context/AppContext';
import { ProductBrand } from './constants/brands.constant';
import EventEditPage from './pages/EventEditPage';
import ScheduleGridPage from './pages/ScheduleGridPage';

interface BrandedAppProps {
  isDesktop: boolean;
  targetedTenant: string;
}

const BrandedApp: React.FC<BrandedAppProps> = ({ isDesktop, targetedTenant }) => {
  const { currentBrand, requestTenantBySubDomain } = useAppContext();

  useEffect(() => {
    requestTenantBySubDomain(targetedTenant);
  }, []);

  const desktopHome = process.env.NODE_ENV !== 'development' ? DesktopSplashPage : ScheduleGridPage;

  return (
    <IonApp className={currentBrand === ProductBrand.Astra ? 'astra' : ''}>
      <IonReactRouter>
        <IonRouterOutlet>
          <Route exact path="/calendar" component={CalendarPage} />
          <Route exact path="/desktop-splash" component={DesktopSplashPage} />
          {isDesktop ? (
            <Route exact path="/" component={desktopHome} />
          ) : (
            <Route exact path="/" component={CalendarPage} />
          )}
          <Route exact path="/event/:eventId/edit" component={EventEditPage} />
          <Route exact path="/event/:eventId" component={EventInfoPage} />
          <Route exact path="/schedule-grid" component={ScheduleGridPage} />
        </IonRouterOutlet>
      </IonReactRouter>
    </IonApp>
  );
};

export default BrandedApp;
