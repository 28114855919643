import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { Auth0Provider } from '@auth0/auth0-react';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import HasuraProvider from './Hasura';
import { IntercomProvider } from 'react-use-intercom';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

ReactDOM.render(
  <React.StrictMode>
    <HasuraProvider>
      // TODO: RT move auth0 credentials and intercom details to config file
      <Auth0Provider
        domain="dev-fqv45rny.us.auth0.com"
        clientId="iqns7E8ODVjvJkGrvhRoISXYhA6xwOtF"
        redirectUri={`${window.location.origin}/calendar`}
      >
        <IntercomProvider appId={'olgq2g3d'}>
          <App />
        </IntercomProvider>
      </Auth0Provider>
    </HasuraProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
