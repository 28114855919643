import { useEffect, useState } from 'react';
import { ApolloError, useMutation, useQuery } from '@apollo/client';
import { useIonLoading, IonItem, IonSelectOption, IonIcon, useIonToast } from '@ionic/react';
import { DateTime } from 'luxon';
import {
  EventTimeSpanContainer,
  StyledDateGrid,
  StyledDateHeading,
  StyledDate,
  StyledIonList,
  StyledIonSelect,
  StyledIonInputContainer,
  StyledInput,
  StyledAutocomplete,
  PencilIcon,
} from './EventTimeAndStatus.styles';
import { ActivityStatus } from '../../utils/ActivityStatusEnum';
import CHANGE_ACTIVITY_STATUS from '../../graphql/mutations/changeActivityStatus';
import { checkmarkOutline, close, pencilSharp } from 'ionicons/icons';
import UPDATE_ACTIVITY_LOCATION from '../../graphql/mutations/updateActivityLocation';
import { LocationById_locations } from '../../GeneratedTypes';
import LOCATIONS_BY_TENANT_ID from '../../graphql/queries/LocationsByTenantId';

interface EventTimeAndStatusEditProps {
  startDateTime: DateTime;
  endDateTime: DateTime;
  activityStatus: ActivityStatus;
  activityId: string;
  activityLocation: string;
  locationID: unknown;
  tenantId: unknown;
}

const EventTimeAndStatusEdit: React.FC<EventTimeAndStatusEditProps> = ({
  startDateTime,
  endDateTime,
  activityStatus,
  activityId,
  activityLocation,
  tenantId,
}) => {
  const spansDays = !startDateTime.hasSame(endDateTime, 'day');
  const [present, dismiss] = useIonLoading();
  const [changeStatus, { loading }] = useMutation(CHANGE_ACTIVITY_STATUS, {
    ignoreResults: true,
  });
  const [presentToast] = useIonToast();

  useEffect(() => {
    loading ? present() : dismiss();
  }, [loading]);

  const {
    loading: locationsLoading,
    data: locationsData,
    error: locationsError,
  } = useQuery(LOCATIONS_BY_TENANT_ID, {
    variables: {
      tenantID: tenantId,
    },
  });

  interface ShowToastI {
    message: string;
  }

  const showToast = ({ message }: ShowToastI) => {
    presentToast({
      keyboardClose: true,
      message,
      duration: 3000,
    });
  };

  const [updateLocation] = useMutation(UPDATE_ACTIVITY_LOCATION, {
    onCompleted: () => showToast({ message: 'Activity location successfully updated.' }),
    onError: () => showToast({ message: 'There was an error trying to update the activity location. Try again.' }),
  });

  const handleSubmit = (id: string, newLocationID: unknown) => {
    switch (id) {
      case 'location':
        updateLocation({
          variables: {
            locationID: newLocationID,
            activityID: activityId,
          },
        });
        break;
      case 'start-time':
        break;
      case 'end-time':
        break;
      default:
        break;
    }
  };

  return (
    <>
      <EventTimeSpanContainer>
        <StyledDateGrid>
          <StyledIonList>
            <IonItem lines="inset">
              <StyledDateHeading>Status</StyledDateHeading>
              <StyledIonSelect
                value={activityStatus}
                onIonChange={(e) => changeStatus({ variables: { id: activityId, status: e.detail.value } })}
              >
                <IonSelectOption value={ActivityStatus.SCHEDULED}>Scheduled</IonSelectOption>
                <IonSelectOption value={ActivityStatus.CANCELED}>Canceled</IonSelectOption>
                <IonSelectOption value={ActivityStatus.REQUESTED}>Requested</IonSelectOption>
              </StyledIonSelect>
            </IonItem>
            <IonItem>
              <StyledDateHeading>{spansDays ? 'Start Date' : 'Date'}</StyledDateHeading>
              <StyledDate>{startDateTime.toLocaleString()}</StyledDate>
            </IonItem>
            {spansDays ? (
              <IonItem>
                <StyledDateHeading>End Date</StyledDateHeading>
                <StyledDate>{endDateTime.toLocaleString()}</StyledDate>
              </IonItem>
            ) : null}
            <DisplayField
              id="start-time"
              label="Start Time"
              text={startDateTime.toLocaleString(DateTime.TIME_SIMPLE)}
              handleSubmit={handleSubmit}
            />
            <DisplayField
              id="end-time"
              label="End Time"
              text={endDateTime.toLocaleString(DateTime.TIME_SIMPLE)}
              handleSubmit={handleSubmit}
            />
            <DisplayField
              locations={{
                data: locationsData?.locations,
                error: locationsError,
                loading: locationsLoading,
              }}
              id="location"
              label="Location"
              text={activityLocation}
              handleSubmit={handleSubmit}
            />
          </StyledIonList>
        </StyledDateGrid>
      </EventTimeSpanContainer>
    </>
  );
};

interface DisplayFieldProps {
  id: string;
  label: string;
  text: string;
  handleSubmit: (id: string, newLocationID: unknown) => void | undefined;
  locations?: {
    data: LocationById_locations[];
    error: ApolloError | undefined;
    loading: boolean;
  };
}

interface NewLocationValueI {
  id: unknown;
  name: string;
}

const DisplayField: React.FC<DisplayFieldProps> = ({ label, text, id, handleSubmit, locations }) => {
  const [editMode, setEditMode] = useState(false);
  const [newLocationValue, setNewLocationValue] = useState<NewLocationValueI>({
    id: 0,
    name: '',
  });

  const handleCheckmarkClick = () => {
    setEditMode(false);
    handleSubmit(id, newLocationValue.id);
  };

  return (
    <IonItem>
      {editMode && id === 'location' ? (
        <>
          <StyledIonInputContainer>
            <StyledAutocomplete
              freeSolo
              id="location-input"
              disableClearable
              options={locations!.data.map((location) => ({
                name: location.name,
                id: location.id,
              }))}
              getOptionLabel={(option) => option.name}
              value={newLocationValue}
              /* eslint-disable @typescript-eslint/no-explicit-any */
              onChange={(event, newValue: any) => {
                setNewLocationValue({
                  id: newValue.id,
                  name: newValue.name,
                });
              }}
              renderInput={(params) => (
                <StyledInput
                  {...params}
                  label="Location"
                  size="small"
                  InputProps={{
                    ...params.InputProps,
                    type: 'search',
                  }}
                />
              )}
            />
            <IonIcon slot="icon-only" icon={close} onClick={() => setEditMode(false)}></IonIcon>
            <IonIcon slot="icon-only" icon={checkmarkOutline} onClick={handleCheckmarkClick}></IonIcon>
          </StyledIonInputContainer>
        </>
      ) : (
        <>
          <StyledDateHeading>{label}</StyledDateHeading>
          <StyledDate>{text}</StyledDate>
          {id === 'location' && <PencilIcon icon={pencilSharp} onClick={() => setEditMode(true)} />}
        </>
      )}
    </IonItem>
  );
};

export default EventTimeAndStatusEdit;
