import React from 'react';
import { DateTime } from 'luxon';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import { MuiTextFieldProps } from '@mui/lab/internal/pickers/PureDateInput';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { StyledDatePickerInput, StyledPseudoPickerInput } from './NewDatePicker.styles';

interface ContainerProps {
  selectedDate: DateTime;
  selectDate: (newDate: DateTime) => void;
}

/* eslint-disable @typescript-eslint/no-explicit-any */
const renderPicker = ({ inputRef, inputProps, InputProps }: MuiTextFieldProps) => {
  return (
    <>
      <StyledPseudoPickerInput>{inputProps?.value}</StyledPseudoPickerInput>
      <StyledDatePickerInput data-testid="new-date-picker" ref={inputRef} {...inputProps} />
      {InputProps?.endAdornment}
    </>
  );
};

const NewDatePicker: React.FC<ContainerProps> = ({ selectedDate, selectDate }) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DatePicker
        label="Basic example"
        components={{
          OpenPickerIcon: ExpandMoreIcon,
        }}
        value={selectedDate?.toJSDate()}
        onChange={(newValue) => {
          if (newValue) {
            selectDate(DateTime.fromJSDate(newValue));
          }
        }}
        inputFormat="MMMM d"
        disableMaskedInput={true}
        renderInput={renderPicker}
      />
    </LocalizationProvider>
  );
};

export default NewDatePicker;
