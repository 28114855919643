import { IonGrid, IonRow, IonCol } from '@ionic/react';
import LocationSnippet from '../LocationSnippet';
import EventTagSummary from '../EventInfo/EventTagSummary';
import { SplitIoComponentProps } from '../../SplitIo';
import EventStatusIndicator from '../EventStatusIndicator';
import { ActivityStatus } from '../../utils/ActivityStatusEnum';
import CHANGE_ACTIVITY_STATUS from '../../graphql/mutations/changeActivityStatus';
import { useMutation } from '@apollo/client';
import { StyledIonItem, StyledTimeText, StyledTitleText } from './EventItem.styles';

export interface EventItemProps extends SplitIoComponentProps {
  eventId: string;
  title: string;
  location: string;
  status: ActivityStatus;
  timeSpan: string;
  tags: string[];
}

const EventItem = (props: EventItemProps): JSX.Element => {
  const { eventId, timeSpan, title, location, tags } = props;
  const status = props.status;
  const [changeStatus, { loading }] = useMutation(CHANGE_ACTIVITY_STATUS, {
    ignoreResults: true,
  });

  const getNewStatus = (currentStatus: ActivityStatus) => {
    // for now we will just round robin, this will get replaced by user selection UI
    switch (currentStatus) {
      case ActivityStatus.SCHEDULED:
        return ActivityStatus.CANCELED;
      case ActivityStatus.CANCELED:
        return ActivityStatus.REQUESTED;
      case ActivityStatus.REQUESTED:
        return ActivityStatus.SCHEDULED;
    }
  };

  return (
    <StyledIonItem data-testid={`event-${eventId}`} href={`/event/${eventId}`}>
      <IonGrid>
        <IonRow>
          <IonCol>
            <StyledTimeText>{timeSpan}</StyledTimeText>
            <EventStatusIndicator
              status={status}
              updating={loading}
              onChangeStatus={() => {
                changeStatus({ variables: { id: eventId, status: getNewStatus(status) } });
              }}
            />
            <StyledTitleText>{title}</StyledTitleText>
            <LocationSnippet location={location}></LocationSnippet>
            <EventTagSummary tags={tags ?? []} />
          </IonCol>
        </IonRow>
      </IonGrid>
    </StyledIonItem>
  );
};

export default EventItem;
