// Create the config for the SDK factory.

import { IonSpinner } from '@ionic/react';
import { SplitTreatments } from '@splitsoftware/splitio-react';
import { ISplitTreatmentsChildProps } from '@splitsoftware/splitio-react/types/types';

const SplitSdkConfig: SplitIO.IBrowserSettings = {
  core: {
    authorizationKey: process.env.NODE_ENV === 'production' ? '47inda674eel9v9chr5ajua74l3783tvp7qh' : 'localhost',
    key: 'key',
  },
};

export interface SplitIoComponentProps {
  allowEventInfoDrillIn?: boolean;
}

// MP: I could not for the life of me get the types right for the SplitIoWrapper.
// Typescript would throw an error when I tried to use generics for the prop types.
// So for now there's no way to tell it the prop types of the component we pass in.

interface ISplitIoWrapper {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  component: (props: any) => JSX.Element;
  flags: [string];
}

type TSplit<T> = ISplitIoWrapper & SplitIoComponentProps & T;

export function SplitIoWrapper<T>({ component, flags, ...props }: TSplit<T>): JSX.Element {
  const Component = component;

  return (
    <SplitTreatments names={flags}>
      {({ treatments, isReady }: ISplitTreatmentsChildProps) => {
        const allowEventInfoDrillIn: boolean = treatments[flags[0]].treatment === 'on';

        return isReady ? (
          <Component allowEventInfoDrillIn={allowEventInfoDrillIn} {...props} />
        ) : (
          <div>
            {' '}
            <IonSpinner />{' '}
          </div>
        );
      }}
    </SplitTreatments>
  );
}

export const featureFlags = {
  SPLIT_REVEAL_EVENT_INFO: 'reveal-event-info',
};

export default SplitSdkConfig;
