import { useQuery } from '@apollo/client';
import { IonContent, IonPage, IonProgressBar } from '@ionic/react';
import { DateTime } from 'luxon';
import { useMemo } from 'react';
import { useParams } from 'react-router';
import EventInfoHeader from '../../components/EventInfo/EventInfoHeader';
import EventTimeAndStatusEdit from '../../components/EventInfo/EventTimeAndStatusEdit';
import UserActivityAffiliationList from '../../components/UserActivityAffiliationList';
import { ActivityById, ActivityByIdVariables, ActivityById_activities } from '../../GeneratedTypes';
import ACTIVITY_BY_ID from '../../graphql/queries/ActivityById';
import { ActivityStatus } from '../../utils/ActivityStatusEnum';
import { Activity } from '../EventInfoPage/EventInfoPage';
import { StyledEventInfoPage } from '../EventInfoPage/EventInfoPage.styles';

type EventParams = {
  eventId: string;
};

export default function EventEditPage(): JSX.Element {
  const { eventId } = useParams<EventParams>();

  const { loading, data } = useQuery<ActivityById, ActivityByIdVariables>(ACTIVITY_BY_ID, {
    variables: {
      activityID: eventId,
    },
  });

  const event = useMemo(() => data?.activities[0], [data]) as ActivityById_activities;

  return loading ? (
    <IonProgressBar type="indeterminate"></IonProgressBar>
  ) : (
    <IonPage>
      {event && (
        <>
          <EventInfoHeader event={event as unknown as Activity} />
          <IonContent fullscreen>
            <StyledEventInfoPage>
              <EventTimeAndStatusEdit
                startDateTime={DateTime.fromISO(event.start_date_time)}
                endDateTime={DateTime.fromISO(event.end_date_time)}
                activityStatus={(event.status ?? ActivityStatus.SCHEDULED) as ActivityStatus}
                activityId={event.id}
                activityLocation={event.location?.name ?? 'TBD'}
                locationID={event.location?.id ?? 'TBD'}
                tenantId={event.tenant_id}
              />
              <UserActivityAffiliationList activity={event as unknown as Activity} />
            </StyledEventInfoPage>
          </IonContent>
        </>
      )}
    </IonPage>
  );
}
